.vOLPD {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 50%);
    border-radius: 1000px;
    background: linear-gradient(rgba(10, 10, 10, 0.6) -112.48%, rgba(110, 0, 229, 0.6) 99.99%);
    filter: blur(6px);
    height: 8px;
    width: calc(100% - 32px);
    z-index: 1;
}
.hjnXua {
    padding: 24px 12px;
    background-color: rgba(207, 207, 207, 0.06);
}
.UBwSK > * {
    border-top: 1px solid rgb(53, 53, 57);
}
.dlQWRi {
    position: relative;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.grNOHG {
    border-top: 1px solid rgb(53, 53, 57);
} 
.gWUPBy {
    position: relative;
    width: calc(100% - 20px);
    height: 10px;
    background-color: rgba(207, 207, 207, 0.06);
    z-index: 2;
}
.grNOHG::before {
    border-bottom: 10px solid rgba(207, 207, 207, 0.06);
}

.cTFYJn {
    position: absolute;
    left: 0px;
    right: unset;
    height: 1px;
    width: 14px;
    top: 50%;
    transform: translate(-85%, -50%) rotate(-45deg);
    background-color: rgb(53, 53, 57);
    z-index: 2;
}
.gLmBdD {
    position: absolute;
    left: unset;
    right: 0px;
    height: 1px;
    width: 14px;
    top: 50%;
    transform: translate(85%, -50%) rotate(45deg);
    background-color: rgb(53, 53, 57);
    z-index: 2;
}
.grNOHG::after {
    border-bottom: 10px solid rgba(207, 207, 207, 0.06);
}
.gWUPBy::after {
    position: absolute;
    content: "";
    top: 0px;
    right: -10px;
    width: 0px;
    height: 0px;
    border-right: 10px solid transparent;
} 
.gWUPBy::before {
    position: absolute;
    content: "";
    top: 0px;
    left: -10px;
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
}
.hvQLqd {
    border-bottom: 1px solid rgb(53, 53, 57);
}

.hvQLqd::before {
    border-top: 10px solid rgba(207, 207, 207, 0.06);
}

.gWUPBy::before {
    position: absolute;
    content: "";
    top: 0px;
    left: -10px;
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
}
 
.eTNqqe {
    position: absolute;
    left: 0px;
    right: unset;
    height: 1px;
    width: 14px;
    top: 50%;
    transform: translate(-85%, -50%) rotate(45deg);
    background-color: rgb(53, 53, 57);
    z-index: 2;
}
.gVHaXq {
    position: absolute;
    left: unset;
    right: 0px;
    height: 1px;
    width: 14px;
    top: 50%;
    transform: translate(85%, -50%) rotate(-45deg);
    background-color: rgb(53, 53, 57);
    z-index: 2;
}
.hvQLqd::after {
    border-top: 10px solid rgba(207, 207, 207, 0.06);
}
.gWUPBy::after {
    position: absolute;
    content: "";
    top: 0px;
    right: -10px;
    width: 0px;
    height: 0px;
    border-right: 10px solid transparent;
}
.gInJTp {
    width: 100%;
    height: 100%;
    flex: 1 1 0%;
    background: rgba(207, 207, 207, 0.06);
    border-right: 1px solid rgb(53, 53, 57);
    border-left: 1px solid rgb(53, 53, 57);
}
.ejlXWS {
    width: 180px;
    height: 180px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(5, 255, 0, 0.4) 0%, rgba(5, 255, 0, 0) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    font-weight: 500;
}
.kBRKUV {
    width: fit-content;
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
}
.faZbtb {
    height: 60px;
    font-size: 18px;
}
.lmNGEv {
    max-width: 100% !important;
}
.hbpQIt:hover:not(:disabled) {
    border-image-source: linear-gradient(-90deg, rgb(255, 255, 255), rgb(100, 67, 182));
    scale: 1.01;
}
.dAmSWC {
    display: flex;
    width: 61px;
    height: 43px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(53, 53, 57);
    transition: all 0.3s ease 0s;
}
.coKMcz input {
     display: block;
    height: 43px;
    width: 309px;
     background: none;
    border: 1px solid rgb(53, 53, 57);
    color: white;
    padding-left: 10px;
}
.dLmlyL input {
    width: 100%;
}