.dlQWRi {
    position: relative;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cIKxLG {
    border-top: 1px solid rgb(53, 53, 57);
}

.kLMfbJ {
    position: relative;
    width: calc(100% - 32px);
    height: 16px;
    background-color: rgba(207, 207, 207, 0.06);
    z-index: 2;
}

.gInJTp {
    width: 100%;
    height: 100%;
    flex: 1 1 0%;
    background: rgba(207, 207, 207, 0.06);
    border-right: 1px solid rgb(53, 53, 57);
    border-left: 1px solid rgb(53, 53, 57);
}

.leUcmd {
    border-bottom: 1px solid rgb(53, 53, 57);
}

.kLMfbJ {
    position: relative;
    width: calc(100% - 32px);
    height: 16px;
    background-color: rgba(207, 207, 207, 0.06);
    z-index: 2;
}

.cIKxLG::before {
    border-bottom: 16px solid rgba(207, 207, 207, 0.06);
}

.kLMfbJ::before {
    position: absolute;
    content: "";
    top: 0px;
    left: -16px;
    width: 0px;
    height: 0px;
    border-left: 16px solid transparent;
}

.dDpolt {
    position: absolute;
    left: 0px;
    right: unset;
    height: 1px;
    width: 23px;
    top: 50%;
    transform: translate(-85%, -50%) rotate(-45deg);
    background-color: rgb(53, 53, 57);
    z-index: 2;
}

.jmGTBd {
    position: absolute;
    left: unset;
    right: 0px;
    height: 1px;
    width: 23px;
    top: 50%;
    transform: translate(85%, -50%) rotate(45deg);
    background-color: rgb(0, 0, 0);
    z-index: 2;
}

.cIKxLG::after {
    border-bottom: 16px solid rgba(207, 207, 207, 0.06);
}

.kLMfbJ::after {
    position: absolute;
    content: "";
    top: 0px;
    right: -16px;
    width: 0px;
    height: 0px;
    border-right: 16px solid transparent;
}

.gInJTp {
    width: 100%;
    height: 100%;
    flex: 1 1 0%;
    background: rgba(207, 207, 207, 0.06);
    border-right: 1px solid rgb(53, 53, 57);
    border-left: 1px solid rgb(53, 53, 57);
}

.grjaPy {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 14px;
}

.grjaPy p:first-child {
    overflow: hidden;
    color: rgb(155, 155, 162);
    text-overflow: ellipsis;
    font-size: 22px;
    line-height: 120%;
}

.grjaPy p:last-child {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: 500;
    line-height: 120%;
    text-transform: uppercase;
}

.leUcmd {
    border-bottom: 1px solid rgb(53, 53, 57);
}

.kLMfbJ {
    position: relative;
    width: calc(100% - 32px);
    height: 16px;
    background-color: rgba(207, 207, 207, 0.06);
    z-index: 2;
}

.leUcmd::before {
    border-top: 16px solid rgba(207, 207, 207, 0.06);
}

.kLMfbJ::before {
    position: absolute;
    content: "";
    top: 0px;
    left: -16px;
    width: 0px;
    height: 0px;
    border-left: 16px solid transparent;
}

.gFTLcQ {
    position: absolute;
    left: 0px;
    right: unset;
    height: 1px;
    width: 23px;
    top: 50%;
    transform: translate(-85%, -50%) rotate(45deg);
    background-color: rgb(53, 53, 57);
    z-index: 2;
}

.lmWtGY {
    position: absolute;
    left: unset;
    right: 0px;
    height: 1px;
    width: 23px;
    top: 50%;
    transform: translate(85%, -50%) rotate(-45deg);
    background-color: rgb(53, 53, 57);
    z-index: 2;
}

.leUcmd::after {
    border-top: 16px solid rgba(207, 207, 207, 0.06);
}

.leUcmd {
    border-bottom: 1px solid rgb(53, 53, 57);
}

.kLMfbJ {
    position: relative;
    width: calc(100% - 32px);
    height: 16px;
    background-color: rgba(207, 207, 207, 0.06);
    z-index: 2;
}

.kLMfbJ::after {
    position: absolute;
    content: "";
    top: 0px;
    right: -16px;
    width: 0px;
    height: 0px;
    border-right: 16px solid transparent;
}

.hPkaII {
    width: 100%;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
}

.izBjId {
    display: flex;
    gap: 24px;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.fpXvEA {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
}

.iBmuNm {
    color: #5072D1;
    font-size: 22px;
    line-height: 120%;
}

.hbpQIt {
    width: 100%;
    max-width: 280px;
    height: 50px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, rgb(255, 255, 255), rgb(100, 67, 182));
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease 0s;
}

.eePRrB {
    transition: all 0.3s ease 0s;
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(104, 71, 184) 90%, rgb(104, 71, 184) 100%) text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 18px;
}

.IEDuw {
    width: 100%;
    background: rgba(207, 207, 207, 0.06);
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 24px;
    flex-direction: row;
}

.gGjWEI {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
}

.jXQZaI {
    flex-direction: column;
}

.IEDuw {
    width: 100%;
    background: rgba(207, 207, 207, 0.06);
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 24px;
    flex-direction: row;
}

.kiqJSt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
    flex-direction: row;
}

.jGcWAi {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: row;
}

.fpXvEA {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
}

.dCcjnL {
    overflow: hidden;
    color: rgb(155, 155, 162);
    text-overflow: ellipsis;
    font-size: 22px;
    line-height: 120%;
}

.eePRrB {
    transition: all 0.3s ease 0s;
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(104, 71, 184) 90%, rgb(104, 71, 184) 100%) text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 18px;
}

.eNJvsB {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}

.hbpQIt:disabled {
    filter: grayscale(0.8);
}

.connect-btn {
    display: blcck;
}


.eQkJa-d {
    background: rgba(207, 207, 207, 0.06);
    display: flex;
    height: 43px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: rgb(155, 155, 162);
    text-overflow: ellipsis;
    font-size: 18px;
    line-height: 120%;
}

.ilKPxf {
    border: 1px solid rgb(53, 53, 57);
    display: flex;
    width: fit-content;
    height: 43px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: rgb(255, 255, 255);
    text-overflow: ellipsis;
    font-size: 18px;
    transition: all 0.2s ease-in-out 0s;
    cursor: pointer;
}

.ilKPxf:disabled {
    cursor: default;
}

.IEDuw {
    width: 100%;
    background: rgba(207, 207, 207, 0.06);
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 24px;
    flex-direction: row;
}



.bclHhC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.jIxhzv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    /* padding: 12px; */
}

.iTowdi {
    border-radius: 6px;
    display: flex;
    padding: 7px 14px;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.1);
}

.iCyQuZ {
    color: rgb(155, 155, 162);
    font-size: 18px;
    line-height: 120%;
}

.hEPdtl {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 100%;
    display: flex;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.lgzvTq {
    border-radius: 100px;
    box-shadow: blue 0px 0px 8px 5px;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.6);
    margin-left: 10px;
}

.kONphL {
    border-radius: 100px;
    box-shadow: green 0px 0px 8px 5px;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.6);
    margin-left: 10px;
}
@media screen and (max-width: 1024px) {
    .connect-btn {
        display: none;
    }
    .jIxhzv {
        width: 100%;
    }

}
@media screen and (max-width: 768px) {

    .IEDuw {
        flex-direction: column;
    }

    .iBmuNm {
        width: 100%;
    }

    .fpXvEA {
        text-align: center;
        font-size: 20px;
    }

    .kiqJSt {
        flex-direction: column;
        gap: 1.5rem;
    }

    .iTowdi {
        padding: 8px 10px;
        gap: 0;
    }
    .iCyQuZ {
        font-size: 12px;
    }
    .hEPdtl {
        font-size: 12px;
        overflow: visible;
    }
  
}