.changedForm {
        background-color: rgb(254, 250, 250);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        /* align-items: center; */
        gap: 1rem;
        width: 300px;
        padding: 5px;
        border-radius: 5px;
        min-height: 300px;
        height: auto;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        
        
}
.changedForm input {
        width: 100%;
        border:1px solid gray;
        font-size: 20px;
        padding: 2px 0 0 3px;
        outline: none;
        color: gray;
        height: 40px;
}
.changedForm button {
        width: 100%;
        background-color: blue;
        color: #fff;
        border-radius: 7px;
        padding: 10px 0;
}