.gInJTp {
    width: 100%;
    height: 100%;
    flex: 1 1 0%;
    background: rgba(207, 207, 207, 0.06);
    border-right: 1px solid rgb(53, 53, 57);
    border-left: 1px solid rgb(53, 53, 57);
}

.crTtFs {
    width: 100%;
    height: 68;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: white;
    font-size: 16px;
    font-weight: 385;
    text-transform: uppercase;
}

.jujpfO {
    border-top: 1px solid rgb(53, 53, 57);
}
.iCDohZ {
    border-radius: 6px 0px 0px 6px;
    background: rgba(255, 255, 255, 0.1);
    /* border-right: 1px solid white; */
    color: blue;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 234px;
    height: 40px;
    cursor: pointer;
    font-weight: 800;
}
.gONgRy {
    width: 100%;
    max-width: 130px;
    height: 40px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, rgb(255, 255, 255), rgb(100, 67, 182));
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease 0s;
}
.ePfABc {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    border-width: 1px;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, rgb(255, 255, 255), rgb(100, 67, 182));
    transition: all 0.3s ease 0s;
}
.selectti-gradient{
    background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.hbpQIt {
    width: 100%;
    max-width: 280px;
    height: 50px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, rgb(255, 255, 255), rgb(100, 67, 182));
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease 0s;
}

.eePRrB {
    transition: all 0.3s ease 0s;
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(104, 71, 184) 90%, rgb(104, 71, 184) 100%) text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 18px;
}